const { pageType } = window.inlineGlobalConfig;

window.bvCallbackArg = window.bvCallbackArg || null;

function makeBVLocaleString(ffLocaleString) {
  const localesMap = {
    en_EU: 'en_GB',
    en_UK: 'en_GB',
    de_EU: 'de_DE',
    fr_EU: 'fr_FR',
    it_EU: 'it_IT',
  };
  return localesMap[ffLocaleString] || ffLocaleString;
}

window.bvCallback = function bvCallback(BV) {
  window.bvCallbackArg = BV;
  // Order confirmation
  if (pageType === 'order-confirmation') {
    const { locale } = window.inlineGlobalConfig;
    const { code, entries, subTotal, deliveryCost, deliveryAddress } = window.inlineOrderConfirmation.orderConfirmation;

    const email = document.querySelector('.click-to-email').innerText;

    const items = entries.reduce(
      (acc, entry) =>
        acc.concat([
          {
            name: entry.product.name,
            price: `${entry.product.price.value}`,
            quantity: `${entry.quantity}`,
            sku: entry.product.baseProduct,
            category: entry.primaryCategoryName,
          },
        ]),
      []
    );

    BV.pixel.trackTransaction({
      orderId: code,
      currency: subTotal.currencyIso,
      total: `${subTotal.value}`,
      shipping: `${deliveryCost.value}`,
      items,
      email,
      locale: makeBVLocaleString(locale.isoCode),
      nickname: deliveryAddress.firstName,
    });
  }

  // Store locator
  const storeLocator = document.querySelector('.store-locator__button');
  if (storeLocator) {
    storeLocator.addEventListener('click', () => {
      BV.pixel.trackConversion({ type: 'StoreLocator' });
    });
  }
};
